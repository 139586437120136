
import Vue from "vue";

enum TooltipPosition {
  TopRight = "top-right",
  TopLeft = "top-left",
  BottomRight = "bottom-right",
  BottomLeft = "bottom-left",
  LeftTop = "left-top",
  LeftBottom = "left-bottom",
  RightTop = "right-top",
  RightBottom = "right-bottom",
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    targetSelector: {
      type: String,
      required: true,
    },
    optionTargetSelector: {
      type: String,
    },
    message: {
      type: String,
      required: true,
      default: "",
    },
    canOutsideClick: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: "閉じる",
    },
    closeTextColor: {
      type: String,
      default: "#629ee2",
    },
    tooltipPosition: {
      type: String as () => TooltipPosition,
      default: TooltipPosition.BottomRight,
    },
    round: {
      type: Boolean,
      default: true,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "",
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    tooltipPadding: {
      type: String,
      default: "8px",
    },
    tooltipBorderRadius: {
      type: String,
      default: "4px",
    },
    highlightPadding: {
      type: Object as () => { top?: number; bottom?: number; left?: number; right?: number },
      default: () => ({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }),
    },
  },
  mounted() {
    this.setTargetElement();
    window.addEventListener("resize", this.setTargetElement);

    document.body.classList.add("no-scroll");

    this.$nextTick(() => {
      setTimeout(() => {
        this.isOverlayAnimated = true;
      }, 500);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setTargetElement);
    document.body.classList.remove("no-scroll");
  },
  data() {
    return {
      isVisible: true,
      isOverlayAnimated: false,
      targetElement: null,
      highlightX: 0,
      highlightY: 0,
      highlightWidth: 0,
      highlightHeight: 0,
      tooltipStyle: {},
      clickableHighlightStyle: {},
    };
  },
  methods: {
    setTargetElement() {
      this.targetElement = document.querySelector(this.targetSelector);
      const mainRect = document.querySelector(this.targetSelector)?.getBoundingClientRect();
      // オプションターゲットの位置を取得
      const optionRect = this.optionTargetSelector
        ? document.querySelector(this.optionTargetSelector)?.getBoundingClientRect()
        : null;

      const { top, bottom, left, right } = this.highlightPadding;

      if (mainRect) {
        if (optionRect) {
          const x1 = Math.min(mainRect.left, optionRect.left);
          const y1 = Math.min(mainRect.top, optionRect.top);
          const x2 = Math.max(mainRect.right, optionRect.right);
          const y2 = Math.max(mainRect.bottom, optionRect.bottom);

          // ハイライトエリアを包含する矩形の位置とサイズを設定
          this.highlightX = x1 - 4 - (left || 0);
          this.highlightY = y1 - 4 - (top || 0);
          this.highlightWidth = x2 - x1 + 8 + (left || 0) + (right || 0);
          this.highlightHeight = y2 - y1 + 8 + (top || 0) + (bottom || 0);
        } else {
          this.highlightX = mainRect.left - 4 - (left || 0);
          this.highlightY = mainRect.top - 4 - (top || 0);
          this.highlightWidth = mainRect.width + 8 + (left || 0) + (right || 0);
          this.highlightHeight = mainRect.height + 8 + (top || 0) + (bottom || 0);
        }

        // 透明クリックエリアのスタイル設定
        this.clickableHighlightStyle = {
          position: "absolute",
          top: `${this.highlightY}px`,
          left: `${this.highlightX}px`,
          width: `${this.highlightWidth}px`,
          height: `${this.highlightHeight}px`,
          pointerEvents: "auto",
          cursor: "pointer",
        };

        switch (this.tooltipPosition) {
          case TooltipPosition.BottomRight:
            this.tooltipStyle = {
              top: `${this.highlightY + this.highlightHeight + 18 + this.offsetY}px`,
              right: `${window.innerWidth - (this.highlightX + this.highlightWidth) - 12 + this.offsetX}px`,
              "--triangle-position": `${this.highlightWidth / 2 - 4}px`,
            };
            break;
          case TooltipPosition.BottomLeft:
            this.tooltipStyle = {
              top: `${this.highlightY + this.highlightHeight + 18 + this.offsetY}px`,
              left: `${this.highlightX - 4 + this.offsetX}px`,
              "--triangle-position": `${this.highlightWidth / 2 - 4}px`,
            };
            break;
          case TooltipPosition.TopRight:
            this.tooltipStyle = {
              bottom: `${window.innerHeight - this.highlightY + 18 + this.offsetY}px`,
              right: `${window.innerWidth - (this.highlightX + this.highlightWidth) - 12 + this.offsetX}px`,
              "--triangle-position": `${this.highlightWidth / 2 - 4}px`,
            };
            break;
          case TooltipPosition.TopLeft:
            this.tooltipStyle = {
              bottom: `${window.innerHeight - this.highlightY + 18 + this.offsetY}px`,
              left: `${this.highlightX - 4 + this.offsetX}px`,
              "--triangle-position": `${this.highlightWidth / 2 - 4}px`,
            };
            break;
          case TooltipPosition.LeftTop:
            this.tooltipStyle = {
              top: `${this.highlightY + this.offsetY}px`,
              right: `${window.innerWidth - this.highlightX + 12 + this.offsetX}px`,
              "--triangle-position": `${this.highlightHeight / 2 - 4}px`,
            };
            break;
          case TooltipPosition.LeftBottom:
            this.tooltipStyle = {
              bottom: `${window.innerHeight - (this.highlightY + this.highlightHeight) + this.offsetY}px`,
              right: `${window.innerWidth - this.highlightX + 12 + this.offsetX}px`,
              "--triangle-position": `${this.highlightHeight / 2 - 4}px`,
            };
            break;
          case TooltipPosition.RightTop:
            this.tooltipStyle = {
              top: `${this.highlightY + this.offsetY}px`,
              left: `${this.highlightX + this.highlightWidth + 16 + this.offsetX}px`,
              "--triangle-position": `${this.highlightHeight / 2 - 4}px`,
            };
            break;
          case TooltipPosition.RightBottom:
            this.tooltipStyle = {
              bottom: `${window.innerHeight - (this.highlightY + this.highlightHeight) + this.offsetY}px`,
              left: `${this.highlightX + this.highlightWidth + 16 + this.offsetX}px`,
              "--triangle-position": `${this.highlightHeight / 2 - 4}px`,
            };
            break;
          default:
            break;
        }

        if (this.maxWidth) {
          this.tooltipStyle["max-width"] = this.maxWidth;
        }
      }
    },
    closeOnboarding() {
      this.isVisible = false;
      this.$emit("onboarding-close-clicked");
      this.$emit("onboarding-finished");
    },
    highlightClick(event) {
      this.targetElement.click();
      this.isVisible = false;
      this.$emit("onboarding-target-clicked");
      this.$emit("onboarding-finished");
    },
    outsideClick() {
      // 外枠のクリックを許可している場合
      if (this.canOutsideClick) {
        this.$emit("onboarding-outside-clicked");
        this.$emit("onboarding-finished");
      }
    },
  },
});

interface Props {
  targetSelector: string;
  optionTargetSelector: string;
  message: string;
  canOutsideClick: boolean;
  closeText: string;
  closeTextColor: string;
  tooltipPosition: string;
  round: boolean;
  circle: boolean;
  maxWidth: string;
  offsetX: number;
  offsetY: number;
  tooltipPadding: String;
  tooltipBorderRadius: String;
  highlightPadding: Object;
}

interface Data {
  isVisible: boolean;
  isOverlayAnimated: boolean;
  targetElement: any;
  highlightX: number;
  highlightY: number;
  highlightWidth: number;
  highlightHeight: number;
  tooltipStyle: object;
  clickableHighlightStyle: object;
}

interface Computed {}

interface Methods {
  setTargetElement(): void;
  closeOnboarding(): void;
  highlightClick(event: any): void;
  outsideClick(): void;
}
