var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "onboarding-overlay", on: { click: _vm.outsideClick } },
        [
          _c("svg", { attrs: { width: "100%", height: "100%" } }, [
            _c("defs", [
              _c("mask", { attrs: { id: "overlay-mask" } }, [
                _c("rect", {
                  attrs: { width: "100%", height: "100%", fill: "white" },
                }),
                _vm.circle
                  ? _c("circle", {
                      attrs: {
                        cx: _vm.highlightX + _vm.highlightWidth / 2,
                        cy: _vm.highlightY + _vm.highlightHeight / 2,
                        r:
                          Math.min(_vm.highlightWidth, _vm.highlightHeight) / 2,
                        fill: "black",
                      },
                    })
                  : _c("rect", {
                      attrs: {
                        x: _vm.highlightX,
                        y: _vm.highlightY,
                        width: _vm.highlightWidth,
                        height: _vm.highlightHeight,
                        fill: "black",
                        rx: _vm.round ? "8" : "0",
                        ry: _vm.round ? "8" : "0",
                      },
                    }),
              ]),
            ]),
            _c("rect", {
              class: [
                "overlay-initial",
                { "overlay-fade": _vm.isOverlayAnimated },
              ],
              attrs: {
                width: "100%",
                height: "100%",
                fill: "rgba(0, 0, 0, 0.5)",
                mask: "url(#overlay-mask)",
              },
            }),
          ]),
          _c("div", {
            staticClass: "clickable-highlight",
            style: _vm.clickableHighlightStyle,
            on: { click: _vm.highlightClick },
          }),
          _vm.isVisible
            ? _c(
                "div",
                {
                  staticClass: "tooltip fade-bounce",
                  class: [
                    "tooltip-initial",
                    _vm.tooltipPosition,
                    { "tooltip-fade": _vm.isOverlayAnimated },
                  ],
                  style: {
                    ..._vm.tooltipStyle,
                    padding: _vm.tooltipPadding,
                    borderRadius: _vm.tooltipBorderRadius,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "main-text",
                    domProps: { innerHTML: _vm._s(_vm.message) },
                  }),
                  _vm.isDisplayedCloseText
                    ? _c(
                        "div",
                        {
                          staticClass: "close",
                          style: { color: _vm.closeTextColor },
                        },
                        [
                          _c("span", { on: { click: _vm.closeOnboarding } }, [
                            _vm._v(_vm._s(_vm.closeText)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }